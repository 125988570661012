import React, { useCallback, useMemo } from 'react'
import { ConditionSlug } from 'assessment-engine/src'
import { graphql, navigate } from 'gatsby'
import AssessmentComponent from '../components/Assessment/AssessmentComponent'
import UserIdentificationLayer, {
  AuthPage
} from '../components/LayerComponents/UserIdentificationLayer'
import SEO from '../components/SEO'
import SimpleHeader from '../components/Layout/SimpleHeader'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

export default function FollowUpAssessmentsPage({
  data: { assessments },
  pageContext,
  location
}: Props) {
  const [authPage] = useQueryParam(
    'authPage',
    withDefault(StringParam, AuthPage.LOGIN)
  )

  const assessmentSlug = useMemo(
    () =>
      assessments?.followUpAssessments?.find(
        (a: any) => a.condition.slug === pageContext.slug
      )?.slug,
    [assessments?.followUpAssessments, pageContext.slug]
  )

  const onComplete = useCallback(async () => {
    navigate('/assessment/follow-up-complete')
  }, [])

  return (
    <UserIdentificationLayer location={location} authPageIfNotAuthed={authPage}>
      <SEO location={location} noIndex={true} />
      <SimpleHeader backButton={false} />
      <AssessmentComponent
        assessmentSlug={assessmentSlug}
        location={location}
        onComplete={onComplete}
      />
    </UserIdentificationLayer>
  )
}

export const query = graphql`
  query FuaPageQuery($siteConf: String!) {
    assessments: contentfulSiteConfig(environmentName: { eq: $siteConf }, node_locale: { eq: "en-US" }) {
      followUpAssessments {
        id
        slug
        name
        title
        metaDescription
        condition {
          slug
        }
      }
    }
  }
`

type Props = {
  pageContext: {
    slug: ConditionSlug
  }
  data: {
    assessments: {
      followUpAssessments: {
        slug: string
        condition: {
          slug: ConditionSlug
        }
      }[]
    }
  }
  location: Location
}
